<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>COGS by Product (Ebay)</p>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;overflow-x: hidden;" class="table_containder" v-if="ExtFListingObj.length > 0 && firstLoading">
            <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
              <div id="chart" class="graphic-containers">
                <apexchart :height="chartHeight" type="bar" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'ebay'" :callParent="'Cost of Goods Sold'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
import deboucneMixin from '@/mixins/debounce.js'
import moment from 'moment'
import API from '@/api/App.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    GlobalFilter,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      chartHeight: '2000',
      ExtFListingObj: [],
      isFilterOpen: true,
      searchLoader: false,
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            columnWidth: '',
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val + ""
            }
          }
        },
        // yaxis: {
        //   min: 0,
        //   max: 1200,
        //   tickAmount : 20
        // },
        tooltip: {
          custom: function({seriesIndex, dataPointIndex, w}) {
            // console.log('series', series)
            // console.log('seriesIndex', seriesIndex)
            // console.log('dataPointIndex', dataPointIndex)
            // console.log('w', w)
            return '<ul style="padding:4px;">' +
            '<li style="display:flex"><b>Item Name</b>: <p style="width:200px !important;overflow-wrap: anywhere;white-space: pre-wrap;">' + w.config.xaxis.categories[dataPointIndex] + '</p></li>' +
            '<li><b>' + w.config.series[seriesIndex].name + '</b>: $' + w.config.series[seriesIndex].data[dataPointIndex].toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</li>' +
            '</ul>';
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      titleObj: [
        {title: 'Item Name', icon: '', value: 'itemSku', sort: false},
      ],
      pagination: {
        sortBy: 'qtySold',
        descending: true
      },
      selectedValue: 'last30Days',
      filterObject: {
        itemSKUList: [],
        itemNameList: [],
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
  },
  methods: {
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
      }
    },
    applyFilter() {
      this.getListing()
    },
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    getListing () {
      this.chartOptions.xaxis.categories = []
      this.series = []
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      API.EbayChannelReportListing(
        utcSTART,
        utcEND,
        false,
        response => {
          this.searchLoader = false
          this.firstLoading = true
          this.ExtFListingObj = response.Data === null ? [] : response.Data
          // console.log(response.Data)
          this.addTitle()
          if (this.ExtFListingObj.length === 1) {
            this.chartOptions.plotOptions.bar.columnWidth = '20%'
          } else {
            this.chartOptions.plotOptions.bar.columnWidth = '70%'
          }
          for (let index = 0; index < this.ExtFListingObj.length; index++) {
            this.chartOptions.xaxis.categories.push(this.ExtFListingObj[index].sku)
            this.series[0].data.push(this.ExtFListingObj[index].tax)
            this.series[1].data.push(this.ExtFListingObj[index].totalDueSeller)
            this.series[2].data.push(this.ExtFListingObj[index].totalFeeBasisAmount)
            this.series[3].data.push(this.ExtFListingObj[index].totalMarketplaceFee)
            this.series[4].data.push(this.ExtFListingObj[index].profitMargin)
          }
          if (this.chartOptions.xaxis.categories.length > 0 && this.chartOptions.xaxis.categories.length < 100) {
            this.chartHeight = '2000'
          } else if (this.chartOptions.xaxis.categories.length > 100 && this.chartOptions.xaxis.categories.length < 200) {
            this.chartHeight = '3500'
          } else if (this.chartOptions.xaxis.categories.length > 200 && this.chartOptions.xaxis.categories.length < 300) {
            this.chartHeight = '5000'
          } else if (this.chartOptions.xaxis.categories.length > 300 && this.chartOptions.xaxis.categories.length < 400) {
            this.chartHeight = '7000'
          } else if (this.chartOptions.xaxis.categories.length > 400 && this.chartOptions.xaxis.categories.length < 500) {
            this.chartHeight = '9000'
          } else if (this.chartOptions.xaxis.categories.length > 500 && this.chartOptions.xaxis.categories.length < 600) {
            this.chartHeight = '10000'
          } else if (this.chartOptions.xaxis.categories.length > 600 && this.chartOptions.xaxis.categories.length < 700) {
            this.chartHeight = '12000'
          } else if (this.chartOptions.xaxis.categories.length > 700 && this.chartOptions.xaxis.categories.length < 800) {
            this.chartHeight = '14000'
          } else if (this.chartOptions.xaxis.categories.length > 800 && this.chartOptions.xaxis.categories.length < 900) {
            this.chartHeight = '16000'
          } else if (this.chartOptions.xaxis.categories.length > 900 && this.chartOptions.xaxis.categories.length < 1000) {
            this.chartHeight = '18000'
          } else if (this.chartOptions.xaxis.categories.length > 1000) {
            this.chartHeight = '20000'
          }
          setTimeout(() => {
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          }, 5000)
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addTitle () {
      this.series = []
       let chartTitle = ['Sum of Tax', 'Sum of Total Due Seller', 'Sum of Total Fee Basis Amount', 'Sum of Total Marketplace Fee', 'Profit Margin']
       for (let index = 0; index < chartTitle.length; index++) {
         this.series.push({
           name: chartTitle[index],
           data: []
         })
       }
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.small_col2 {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.smallx_col {
  min-width: 250px !important;
  width: 250px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.graphic-containers {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>COGS by Product (Ebay) (TEXT ONLY)</p>
            <div class="flex">
              <button v-if="firstLoading && ExtFListingObj.length > 0" @click="openDrag()" style="letter-spacing: 1px;" class="px-2 mx-3 heading-6 flex items-center text-white bg-primary2 cursor-pointer rounded">
                <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                Reorder Column
              </button>
              <button @click="exportReport()" :class="firstLoading && ExtFListingObj.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 flex items-center">
                <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                Export to Excel
              </button>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse;" class="table_containder relative max-w-max" v-if="firstLoading">
            <div class="text-text2 min-w-min heaer_row border border-gray1 font-bold flex heading-6 py-1 items-center" style="position: sticky;top: 0;background: white;">
              <div class="xsmall_col centerLine justify-center" >
                <span class="">Index</span>
              </div>
              <div class="small_col2  flex items-center" :style="`width:` +  titleObj[0].width + `; !important;text-align:` + titleObj[0].align + `; !important; !important;justify-content:` + titleObj[0].align + `;`">
                <span class="cursor-pointer " @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallx_col  flex items-center" :style="`width:` +  titleObj[1].width + `; !important;text-align:` + titleObj[1].align + `; !important; !important;justify-content:` + titleObj[1].align + `;`">
                <span class="cursor-pointer " @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" :style="`width:` +  titleObj[2].width + `; !important;text-align:` + titleObj[2].align + `; !important; !important;justify-content:` + titleObj[2].align + `;`">
                <span class="cursor-pointer " @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" :style="`width:` +  titleObj[3].width + `; !important;text-align:` + titleObj[3].align + `; !important; !important;justify-content:` + titleObj[3].align + `;`">
                <span class="cursor-pointer " @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" :style="`width:` +  titleObj[4].width + `; !important;text-align:` + titleObj[4].align + `; !important; !important;justify-content:` + titleObj[4].align + `;`">
                <span class="cursor-pointer " @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center" :style="`width:` +  titleObj[5].width + `; !important;text-align:` + titleObj[5].align + `; !important; !important;justify-content:` + titleObj[5].align + `;`">
                <span class="cursor-pointer " @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` +  cardHight + `px; !important`" class="">
              <div id="DivValue"></div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'ebay'" :callParent="'Cost of Goods Sold'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
      <div v-if="deleteConfirm" class="popup_overlay">
      <div style="width: 90%;" class="custom_dialog">
        <div class="text-text1 px-4 py-3 flex justify-between items-center">
          <span class="heading-3">Change Column display order</span>
          <div class="flex justify-end">
            <button class="bg-secondary text-white shadow mr-2 rounded-full px-4 py-1.5" style="letter-spacing: 1px;" @click="deleteConfirm = false;resetColumn()">Reset</button>
            <button class="bg-primary2 text-white shadow mr-2 rounded-full px-4 py-1.5" @click="deleteConfirm = false;saveToLocal()"><i class="fas fa-times h-5 w-5 text-white pt-1"></i></button>
          </div>
        </div>
        <hr>
        <div class="p-2 overflow-auto" style="max-height: 970px;">
          <span class="heading-4 text-text1">● Drag chip left/right to change Column display order</span>
          <draggable class="flex mt-4" :list="titleObj" group="people" @start="drag = true" @end="dragArray('false')" id='list'>
            <div v-for="(data, index) in titleObj" :key="index" class="bg-white text-sm p-1 items-center">
                <div class="flex items-center">
                  <button class="border border-gray2 rounded-lg py-2 px-3 cursor-move">{{data.title}}</button>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import draggable from 'vuedraggable'
import axios from 'axios'
import deboucneMixin from '@/mixins/debounce.js'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    draggable,
    GlobalFilter,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      deleteConfirm: false,
      isFilterOpen: true,
      searchLoader: false,
      ExtFListingObj: [],
      resetColumnList: [
        {title: 'Sku', icon: '', value: 'sku', sort: false, width: '200px', align: 'start'},
        {title: 'Sum of Tax', icon: '', value: 'tax', sort: true, width: '190px', align: 'right'},
        {title: 'Sum of Total Due Seller', icon: '', value: 'totalDueSeller', sort: false, width: '190px', align: 'right'},
        {title: 'Sum of Total Fee Basis Amount', icon: '', value: 'totalFeeBasisAmount', sort: false, width: '190px', align: 'right'},
        {title: 'Sum of Total Marketplace Fee', icon: '', value: 'totalMarketplaceFee', sort: false, width: '190px', align: 'right'},
        {title: 'Profit Margin', icon: '', value: 'profitMargin', sort: false, width: '190px', align: 'right'},
      ],
      titleObj: [
        {title: 'Sku', icon: '', value: 'sku', sort: false, width: '200px', align: 'start'},
        {title: 'Sum of Tax', icon: '', value: 'tax', sort: true, width: '190px', align: 'right'},
        {title: 'Sum of Total Due Seller', icon: '', value: 'totalDueSeller', sort: false, width: '190px', align: 'right'},
        {title: 'Sum of Total Fee Basis Amount', icon: '', value: 'totalFeeBasisAmount', sort: false, width: '190px', align: 'right'},
        {title: 'Sum of Total Marketplace Fee', icon: '', value: 'totalMarketplaceFee', sort: false, width: '190px', align: 'right'},
        {title: 'Profit Margin', icon: '', value: 'profitMargin', sort: false, width: '190px', align: 'right'},
      ],
      pagination: {
        sortBy: 'qtySold',
        descending: true
      },
      selectedValue: 'last30Days',
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    let filterDataStoredInLocalStorage = localStorage.getItem('ebayproductList')
    if (filterDataStoredInLocalStorage !== null) {
      let data = JSON.parse(filterDataStoredInLocalStorage)
      this.titleObj = data
      this.dragArray('false')
    } else {
      let el2 = document.getElementById('DivValue')
      let outputA = "<div id=\"outerboxA\">"
      for (let indexA = 0; indexA < this.ExtFListingObj.length; indexA++) {
        outputA +='<div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-center cursor-pointer hover:bg-gray-50"><div style="text-align:center;width:54px!important;padding-right: 8px !important;white-space: pre-wrap;" class="xsmall_col"><span class="heading-6-1 text-text1"> '+ (indexA + 1) + '</span></div><div style="width:200px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col"><span class="heading-6 text-text1">'+ this.ExtFListingObj[indexA].sku +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[indexA].tax) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[indexA].totalDueSeller) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[indexA].totalFeeBasisAmount) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[indexA].totalMarketplaceFee) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[indexA].profitMargin) +'</span></div></div>'
      }
      outputA += '</div>'
      el2.innerHTML = outputA
    }
  },
  watch: {
  },
  methods: {
    amountFormaterWithToFix (amount) {
      let value = parseFloat(amount).toFixed(2)
      return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    },
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    dragArray (value) {
      console.log('value', value)
      let el1 = document.getElementById('DivValue')
      el1.innerHTML = ''
      let output = "<div id=\"outerbox\">"
      let TempKey = '' 
      if (value === 'reset') {
        let tempArr = JSON.parse(JSON.stringify(this.resetColumnList))
        this.titleObj = tempArr
        for (let II = 0; II < this.ExtFListingObj.length; II++) {
          output += '<div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-center cursor-pointer hover:bg-gray-50"><div style="text-align:center;width:54px!important;padding-right: 8px !important;white-space: pre-wrap;" class="xsmall_col"><span class="heading-6-1 text-text1"> '+ (II + 1) + '</span></div>'
          for (let JJ = 0; JJ < this.resetColumnList.length; JJ++) {
            TempKey = this.resetColumnList[JJ].value
            let renderValue = ''
            let CSSVal = ''
            if (TempKey === 'profitMargin' || TempKey === 'tax' || TempKey === 'totalDueSeller' || TempKey === 'totalFeeBasisAmount' || TempKey === 'totalMarketplaceFee') {
              renderValue = this.amountFormaterWithToFix(this.ExtFListingObj[II][TempKey])
              CSSVal = 'text-aligh:right !important;float: right !important;'
            } else {
              renderValue = this.ExtFListingObj[II][TempKey]
            }
            output +='<div class="" style="width:' +  this.resetColumnList[JJ].width + '!important;padding-right: 8px !important;white-space: pre-wrap;"><span style="' + CSSVal + '" class="heading-6-1 text-text1"> '+ renderValue + '</span></div><br>'
          }
          output += '</div>'
        }
        localStorage.removeItem('ebayproductList')
      } else {
        for (let II = 0; II < this.ExtFListingObj.length; II++) {
          output += '<div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-center cursor-pointer hover:bg-gray-50"><div style="text-align:center;width:54px!important;padding-right: 8px !important;white-space: pre-wrap;" class="xsmall_col"><span class="heading-6-1 text-text1"> '+ (II + 1) + '</span></div>'
          for (let JJ = 0; JJ < this.titleObj.length; JJ++) {
            TempKey = this.titleObj[JJ].value
            let renderValue = ''
            let CSSVal = ''
            if (TempKey === 'profitMargin' || TempKey === 'tax' || TempKey === 'totalDueSeller' || TempKey === 'totalFeeBasisAmount' || TempKey === 'totalMarketplaceFee') {
              renderValue = this.amountFormaterWithToFix(this.ExtFListingObj[II][TempKey])
              CSSVal = 'text-aligh:right !important;float: right !important;'
            } else {
              renderValue = this.ExtFListingObj[II][TempKey]
            }
            output +='<div class="" style="width:' +  this.titleObj[JJ].width + '!important;padding-right: 8px !important;white-space: pre-wrap;"><span style="' + CSSVal + '" class="heading-6-1 text-text1"> '+ renderValue + '</span></div><br>'
          }
          output += '</div>'
        }
      }
      output += '</div>'
      el1.innerHTML = output;
      this.drag = false
    },
    saveToLocal () {
      localStorage.setItem('ebayproductList', JSON.stringify(this.titleObj))
    },
    resetColumn () {
      this.dragArray('reset')
    },
    openDrag () {
      this.deleteConfirm = true
      console.log('click')
    },
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      API.EbayChannelReportListing(
        utcSTART,
        utcEND,
        false,
        response => {
          this.ExtFListingObj = response.Data === null ? [] : response.Data
          console.log(response.Data)
          setTimeout(() => {
            let filterDataStoredInLocalStorage = localStorage.getItem('ebayproductList')
            if (filterDataStoredInLocalStorage !== null) {
              let data = JSON.parse(filterDataStoredInLocalStorage)
              this.titleObj = data
              this.dragArray('false')
              this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            } else {
              this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
              let el1 = document.getElementById('DivValue')
              let output = "<div id=\"outerbox\">"
              for (let index = 0; index < this.ExtFListingObj.length; index++) {
                output +='<div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-center cursor-pointer hover:bg-gray-50"><div style="text-align:center;width:54px!important;padding-right: 8px !important;white-space: pre-wrap;" class="xsmall_col"><span class="heading-6-1 text-text1"> '+ (index + 1) + '</span></div><div style="width:200px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col"><span class="heading-6 text-text1">'+ this.ExtFListingObj[index].sku +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[index].tax) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[index].totalDueSeller) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[index].totalFeeBasisAmount) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[index].totalMarketplaceFee) +'</span></div><div style="width:190px!important;padding-right: 8px !important;white-space: pre-wrap;" class="small_col rightText text-right"><span class="heading-6 text-text1">'+ this.amountFormaterWithToFix(this.ExtFListingObj[index].profitMargin) +'</span></div></div>'
              }
              output += '</div>'
              el1.innerHTML = output;
            }
          }, 3000)
          this.searchLoader = false
          this.firstLoading = true
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    async exportReport () {
      if (this.firstLoading && this.ExtFListingObj.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        this.searchLoader = true
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = 'COGS Producy(Ebay) (Text only)-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/dpp/ebay/cogs/product', `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    sortByColumn (i, val1, val2) {
      console.log('i', i)
      console.log('val1', val1)
      console.log('val2', val2)
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        console.log('click 1')
        this.ExtFListingObj.sort(function (a, b) {
          console.log('a', a)
          console.log('b', b)
          if (val2 === 'itemSku') {
            var nameA = a.itemSku
            var nameB = b.itemSku
          }
          a.itemSkuList.sort(function (c, d) {
            console.log('ccccccccccccccccccccccccccccccc', c)
            console.log('ddddddddddddddddddddddddddddddd', d)
            if (val2 === 'itemCost') {
              nameA = c.itemCost
              nameB = d.itemCost
            } else if (val2 === 'itemName') {
              nameA = c.itemName
              nameB = d.itemName
            } else if (val2 === 'qtySold') {
              nameA = c.qtySold
              nameB = d.qtySold
            } else if (val2 === 'revenue') {
              nameA = c.revenue
              nameB = d.revenue
            } else if (val2 === 'shipping') {
              nameA = c.shipping
              nameB = d.shipping
            } else if (val2 === 'tax') {
              nameA = c.tax
              nameB = d.tax
            }
          })
          b.itemSkuList.sort(function (e, f) {
            console.log('ccccccccccccccccccccccccccccccc', e)
            console.log('ddddddddddddddddddddddddddddddd', f)
            if (val2 === 'itemCost') {
              nameA = e.itemCost
              nameB = f.itemCost
            } else if (val2 === 'itemName') {
              nameA = e.itemName
              nameB = f.itemName
            } else if (val2 === 'qtySold') {
              nameA = e.qtySold
              nameB = f.qtySold
            } else if (val2 === 'revenue') {
              nameA = e.revenue
              nameB = f.revenue
            } else if (val2 === 'shipping') {
              nameA = e.shipping
              nameB = f.shipping
            } else if (val2 === 'tax') {
              nameA = e.tax
              nameB = f.tax
            }
          })
          // console.log('nameA-------------------- 1', nameA)
          // console.log('nameB-------------------- 1', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.ExtFListingObj.reverse(function (a, b) {
          if (val2 === 'itemSku') {
            var nameA = a.itemSku
            var nameB = b.itemSku
          }
          a.itemSkuList.sort(function (c, d) {
            console.log('ccccccccccccccccccccccccccccccc', c)
            console.log('ddddddddddddddddddddddddddddddd', d)
            if (val2 === 'itemCost') {
              nameA = c.itemCost
              nameB = d.itemCost
            } else if (val2 === 'itemName') {
              nameA = c.itemName
              nameB = d.itemName
            } else if (val2 === 'qtySold') {
              nameA = c.qtySold
              nameB = d.qtySold
            } else if (val2 === 'revenue') {
              nameA = c.revenue
              nameB = d.revenue
            } else if (val2 === 'shipping') {
              nameA = c.shipping
              nameB = d.shipping
            } else if (val2 === 'tax') {
              nameA = c.tax
              nameB = d.tax
            }
          })
          b.itemSkuList.sort(function (e, f) {
            console.log('ccccccccccccccccccccccccccccccc', e)
            console.log('ddddddddddddddddddddddddddddddd', f)
            if (val2 === 'itemCost') {
              nameA = e.itemCost
              nameB = f.itemCost
            } else if (val2 === 'itemName') {
              nameA = e.itemName
              nameB = f.itemName
            } else if (val2 === 'qtySold') {
              nameA = e.qtySold
              nameB = f.qtySold
            } else if (val2 === 'revenue') {
              nameA = e.revenue
              nameB = f.revenue
            } else if (val2 === 'shipping') {
              nameA = e.shipping
              nameB = f.shipping
            } else if (val2 === 'tax') {
              nameA = e.tax
              nameB = f.tax
            }
          })
          console.log('nameA-------------------- 2', nameA)
          console.log('nameB-------------------- 2', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
  },
  destroyed() {
    localStorage.removeItem('ebayproductList')
  },
  beforeDestroy () {
    localStorage.removeItem('ebayproductList')
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.small_col2 {
  /* min-width: 200px !important; */
  /* width: 200px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  /* min-width: 190px !important; */
  /* width: 190px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.smallx_col {
  /* min-width: 190px !important; */
  /* width: 190px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>

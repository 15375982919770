<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="">
          <EBayBarListing></EBayBarListing>
        </div>
        <div class="mt-12">
          <EBayChartTextOnly></EBayChartTextOnly>
        </div>
        <div class="mt-12">
          <EbayByProducts></EbayByProducts>
        </div>
        <div class="mt-12">
          <EBayProductTextOnly></EBayProductTextOnly>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import EBayBarListing from './eBayBarLisiting.vue';
import EbayByProducts from './eBayChannelBar.vue';
import EBayChartTextOnly from './eBayChannelTextOnly.vue';
import EBayProductTextOnly from './eBayProductTextOnly.vue';

export default {
  components: {
    EbayByProducts,
    EBayProductTextOnly,
    EBayBarListing,
    EBayChartTextOnly
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'COGS ◾ EBay'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
